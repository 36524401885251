var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post
    ? _c(
        "v-container",
        { staticClass: "wp-page news-detail", on: { click: _vm.clicked } },
        [
          _c("breadcrumb", { attrs: { items: _vm.breadcrumb } }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("h1", {
                    staticClass: "mt-3 mb-6",
                    domProps: { innerHTML: _vm._s(_vm.post.title.rendered) }
                  }),
                  _c("v-img", {
                    staticClass: "mb-3",
                    attrs: { src: _vm.post.featured_image_url }
                  }),
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-uppercase pt-2" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$dayjs(_vm.post.date).format("LL")) +
                          " "
                      )
                    ]
                  ),
                  _c("p", {
                    staticClass: "mt-6",
                    domProps: { innerHTML: _vm._s(_vm.post.content.rendered) }
                  }),
                  _c("PostsNav", {
                    attrs: { postType: _vm.type, postId: _vm.post.id }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                _c(
                  "div",
                  { staticClass: "latest-news-container pa-4 grey lighten-1" },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t(`news.latest.${_vm.type}`)))
                    ]),
                    _c("LatestPosts", { attrs: { type: _vm.type } })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }