import { render, staticRenderFns } from "./PostsNav.vue?vue&type=template&id=75b56d56&scoped=true"
import script from "./PostsNav.vue?vue&type=script&lang=js"
export * from "./PostsNav.vue?vue&type=script&lang=js"
import style0 from "./PostsNav.vue?vue&type=style&index=0&id=75b56d56&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b56d56",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/rifatosmani/workspace/Abbondanza/ebsn-front-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75b56d56')) {
      api.createRecord('75b56d56', component.options)
    } else {
      api.reload('75b56d56', component.options)
    }
    module.hot.accept("./PostsNav.vue?vue&type=template&id=75b56d56&scoped=true", function () {
      api.rerender('75b56d56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/wordpress/PostsNav.vue"
export default component.exports