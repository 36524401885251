var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "transparent" },
    _vm._l(_vm.posts, function(post, idx) {
      return _c(
        "router-link",
        {
          key: post.id,
          staticClass: "latest-post-link",
          attrs: {
            to: {
              name: _vm.routeName,
              params: { postName: post.slug }
            }
          }
        },
        [
          _c(
            "v-list-item",
            { staticClass: "pb-2 latest-post-item d-flex flex-column" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex py-2 justify-center align-center" },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("img", {
                      staticClass: "rounded",
                      attrs: { src: post.featured_image_url }
                    })
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "lastest-post-title secondary--text font-weight-bold"
                      },
                      [_vm._v(" " + _vm._s(post.title.rendered) + " ")]
                    )
                  ])
                ],
                1
              ),
              idx !== _vm.posts.length - 1
                ? _c("v-divider", { staticClass: "w-100 my-1" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }