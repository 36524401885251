<template>
  <v-container class="wp-page news-detail" v-if="post" @click="clicked">
    <breadcrumb :items="breadcrumb" />
    <!-- <router-link
      class="d-flex align-center news-detail-go-back black--text"
      :to="{
        name: 'NewsList'
      }"
    >
      <v-icon color="primary" class="pr-2">$chevronLeft</v-icon>
      <span class="font-weight-bold primary--text">{{
        $t("news.goBack")
      }}</span></router-link
    > -->
    <v-row>
      <v-col cols="12" md="8">
        <h1 class="mt-3 mb-6" v-html="post.title.rendered"></h1>
        <v-img class="mb-3" :src="post.featured_image_url" />
        <div class="font-weight-bold text-uppercase pt-2">
          {{ $dayjs(post.date).format("LL") }}
        </div>
        <p class="mt-6" v-html="post.content.rendered"></p>
        <PostsNav :postType="type" :postId="post.id"></PostsNav>
      </v-col>
      <v-col cols="12" md="4">
        <div class="latest-news-container pa-4 grey lighten-1">
          <h3>{{ $t(`news.latest.${type}`) }}</h3>
          <LatestPosts :type="type"></LatestPosts>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
@import url("/p/wp-content/themes/abbondanza/styles-common.css?ver=wp");
.news-detail {
  &-go-back {
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import CMService from "~/service/cmService";
import LatestPosts from "@/components/wordpress/LatestPosts.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PostsNav from "@/components/wordpress/PostsNav.vue";

export default {
  components: { LatestPosts, Breadcrumb, PostsNav },
  name: "NewsDetail",
  mixins: [clickHandler],
  props: {
    type: {
      type: String,
      required: true,
      default: "posts"
    }
  },
  data() {
    return {
      post: null
    };
  },
  methods: {
    async fetchNews(slug) {
      const res = await CMService.getCustomPostBySlug(this.type, slug);
      if (res && res.length) {
        this.post = res[0];
        this.type = this.post.type;
      }
    }
  },
  mounted() {
    this.fetchNews(this.$route.params.postName);
  },
  watch: {
    "$route.params.postName"() {
      this.fetchNews(this.$route.params.postName);
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    if (this.post) {
      return {
        title: this.post.title.rendered + " Gala",
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.path}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.post.title.rendered + " Gala"
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: this.post.title.rendered + " Gala"
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            property: "og:image:alt",
            content: this.post.title.rendered + " Gala"
          },
          {
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: this.post.title.rendered + " Gala"
          },
          {
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
